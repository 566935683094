import {
  RawMossTransaction,
  random,
  uuidV4,
} from '@fresh-stack/fullstack-commons';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internals/models';
import { addDays, format } from 'date-fns';
import * as XLSX from 'xlsx';

const randomEntry = <T,>(input: T[]): T => {
  const index = random(0, input.length - 1, false);
  return input[index];
};

export const generateMossCsv = (
  range: NonEmptyDateRange<Date>,
  currency: string,
) => {
  const resultCsv = XLSX.utils.json_to_sheet(
    generateMossTransactions(range, currency),
  );
  return resultCsv;
};

export const generateMossTransactions = (
  range: NonEmptyDateRange<Date>,
  currency: string,
): RawMossTransaction[] => {
  const result: RawMossTransaction[] = [];
  const [startDate, endDate] = range;

  for (let date = startDate; date < endDate; date = addDays(date, 1)) {
    const x = random(1, 5, false);
    for (let i = 0; i < x; i++) {
      const description = randomEntry(descriptions);
      const transaction: RawMossTransaction = {
        'Transaction State': 'ACCEPTED',
        'Payment Date': format(date, 'dd MMM yyyy'),
        'Booking Date': format(date, 'dd MMM yyyy'),
        'Settlement Date': format(date, 'dd MMM yyyy'),
        'Transaction ID': uuidV4(),
        Amount: random(-1000, 1000, true).toFixed(2),
        Currency: currency,
        'Original Amount': undefined,
        'Original Currency': undefined,
        'Conversion Rate': 1,
        'Merchant Name': description,
        Category: '',
        'Account Number': '10000',
        Note: randomEntry(notes),
        Cardholder: randomEntry(names),
        'Card Used': '0000',
        'Team Name': 'Moss',
        'Cost Center - Team': 'Moss',
        'Cost Carrier - Name': undefined,
        'VAT Code': undefined,
        'VAT Name': undefined,
        'VAT Rate': undefined,
        'Moss Balance Account': '1000',
        'First Export Date': new Date().toISOString(),
        'Cash In Transit Account': '14601',
        'Invoice Number': undefined,
        'Supplier Name': 'Default Moss Supplier',
        'Supplier Account': '1111111',
        'Invoice File Name': undefined,
        'Merchant and Card Description': '',
        'Moss Record URL': undefined,
      };
      result.push(transaction);
    }
  }

  return result;
};

const names = [
  'John Smith',
  'Emily Johnson',
  'Michael Brown',
  'Linda White',
  'Robert Davis',
  'Jessica Miller',
  'David Wilson',
  'Sarah Moore',
  'Charles Taylor',
  'Karen Anderson',
  'Joseph Thomas',
  'Susan Jackson',
  'Daniel Harris',
  'Nancy Martin',
  'Paul Thompson',
  'Lisa Garcia',
  'Mark Martinez',
  'Betty Robinson',
  'Steven Clark',
  'Barbara Rodriguez',
  'Jason Lewis',
  'Mary Lee',
  'Timothy Walker',
  'Patricia Hall',
  'Thomas Allen',
  'Dorothy Young',
  'Brian Scott',
  'Jennifer Adams',
  'Gary Nelson',
  'Michelle King',
];

const notes = [
  'Google Ads',
  'Facebook Ads',
  'Adobe Subscription',
  'Performance Marketing',
  'Bing Ads',
  'LinkedIn Advertising',
  'Twitter Ads',
  'Instagram Ads',
  'YouTube Advertising',
  'TikTok Ads',
  'Snapchat Ads',
  'Pinterest Ads',
  'Canva Pro Subscription',
  'Salesforce Marketing Cloud',
  'Mailchimp Subscription',
  'HubSpot CRM',
  'SEMrush Subscription',
  'Ahrefs Subscription',
  'Moz Pro Subscription',
  'Google Analytics Premium',
  'Amazon Advertising',
  'Shopify Marketing Tools',
  'Microsoft 365 Subscription',
  'WordPress Premium Plans',
  'Buffer for Social Media Management',
  'Hootsuite Enterprise',
  'Wix Premium Subscriptions',
  'Adobe Marketo Engage',
  'Constant Contact Email Marketing',
  'Zoho CRM Tools',
];

const descriptions = [
  'AMAZON WEB SERVICES',
  'STARBUCKS STORE 4356',
  'UBER *TRIP HELP.UBER.COM',
  'APPLE ITUNES STORE',
  'TARGET T-1927',
  'WALMART SUPERCENTER',
  'COSTCO WHSE #1124',
  'NETFLIX.COM',
  'PAYPAL *MICROSOFT',
  'SHELL OIL 123456',
  'CHEVRON 0201268',
  'WHOLEFDS 10202',
  "MCDONALD'S F1234",
  'CVS/PHARMACY #0444',
  'BEST BUY 00001234',
  'HOME DEPOT 19283',
  'DELTA AIR 006728192',
  'HILTON HOTELS',
  'AMC ONLINE',
  'SPOTIFY USA',
  'LYFT RIDE THU 10PM',
  'GODADDY.COM LLC',
  'AT&T BILL PAYMENT',
  'VERIZON WRLS IVR VB',
  'T-MOBILE.COM PAYMENT',
  'KROGER LIMITED P 213',
  'AIRBNB RESERVATION',
  'YOUTUBE PREMIUM',
  'DOORDASH ORDER',
  'CHIPOTLE 0427',
  "DOMINO'S PIZZA 7329",
  'NEW YORK TIMES DIGITAL',
  'COMCAST XFINITY',
  'FITBIT.COM ORDER',
  'SQU*SQ *SQUARE INC',
  'PGE*UTILITY PAYMENT',
  'LA FITNESS MEMBERSHIP',
  'PETSMART 2231',
  'IKEA PURCHASE 567',
  'ZOOM.US SUBSCRIPTION',
  'AUDIBLE MEMBERSHIP',
  'IN-N-OUT BURGER #123',
  'FANDANGO.COM ORDER',
  'EQUIFAX*ECOMM',
  'UBER EATS ORDER',
  'OFFICE DEPOT #1234',
  'SEPHORA USA, INC',
  'BIG LOTS #0324',
  'DUNKIN #11234',
  'NORDSTROM 034212',
];
